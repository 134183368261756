import React, { useState, useEffect } from "react";
import FloatingOrb from "./orb";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { StaticQuery, graphql } from "gatsby";



const Layout = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if(window.innerWidth > 768) {
      setIsMobile(true);
    }
  })

  return (
    <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "letters.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `}
    render={data => {
      const image = getImage(data.file.childImageSharp.gatsbyImageData);
      return (
        <div className="flex flex-col md:flex-row h-screen">
          <div className="p-6 w-auto md:w-full md:h-fullrelative md:mr-1/6"
               style={{}}>
            <div className="absolute top-0 right-0 w-1/2 md:w-1/6 h-1/8 md:h-1/4 md:order-2">
              <FloatingOrb />
            </div>
            <main style={{marginRight: isMobile ? `17%` : ``, marginBottom: `15vw`}}>{children}</main>
          </div>
          <div className="fixed bottom-0 w-full">
            {image && <GatsbyImage image={image} alt="Letters" />}
            </div>
        </div>
      );
    }} />
  );
};

export default Layout;
