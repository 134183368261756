import React, { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';
import { ChromePicker } from 'react-color';

const FloatingOrb = () => {
  const mountRef = useRef(null);
  const [materialType, setMaterialType] = useState('metal3');
  const [color, setColor] = useState('#d0a557');
  const sphereRef = useRef(null); // Ref to access the sphere mesh


  const textureLoader = new THREE.TextureLoader();
  const metalBasePath = '/textures/metal/';

  // Load metal textures
  const metalColorMap = textureLoader.load(`${metalBasePath}Metal038_1K-JPG_Color.jpg`);
  const metalRoughnessMap = textureLoader.load(`${metalBasePath}Metal038_1K-JPG_Roughness.jpg`);
  const metalNormalMap = textureLoader.load(`${metalBasePath}Metal038_1K-JPG_NormalGL.jpg`);
  const metalDisplacementMap = textureLoader.load(`${metalBasePath}Metal038_1K-JPG_Displacement.jpg`);
  const metalMetalnessMap = textureLoader.load(`${metalBasePath}Metal038_1K-JPG_Metalness.jpg`);


  const materials = {
    metal3: new THREE.MeshStandardMaterial({
      color,
      map: metalColorMap,
      roughnessMap: metalRoughnessMap,
      normalMap: metalNormalMap,
      displacementMap: metalDisplacementMap,
      displacementScale: 0,
      aoMap: metalMetalnessMap,
      metalnessMap: metalMetalnessMap,
      emissiveIntensity: 0.1,
      roughness: 0.6,
      metalness: 0.7,
    })
  };


  
  useEffect(() => {
    // Scene setup
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, mountRef.current.clientWidth / mountRef.current.clientHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(mountRef.current.clientWidth, mountRef.current.clientHeight);
    renderer.shadowMap.enabled = true;
    mountRef.current.appendChild(renderer.domElement);

    // Lighting
    const ambientLight = new THREE.AmbientLight(0x404040);
    scene.add(ambientLight);

    const pointLight1 = new THREE.PointLight(0xffa500, 1, 100);
    pointLight1.position.set(5, 5, 5);
    pointLight1.castShadow = true;
    scene.add(pointLight1);

    const pointLight2 = new THREE.PointLight(0xffa500, 0.5, 100);
    pointLight2.position.set(-5, -5, -5);
    pointLight2.castShadow = true;
    scene.add(pointLight2);

    const pointLight3 = new THREE.PointLight(0xffa500, 0.5, 100);
    pointLight3.position.set(0, 5, -5);
    pointLight3.castShadow = true;
    scene.add(pointLight3);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
    directionalLight.position.set(1, 1, 1).normalize();
    directionalLight.castShadow = true;
    scene.add(directionalLight);

    // Sphere geometry
    const geometry = new THREE.SphereGeometry(1, 32, 32);

    // Sphere mesh
    const sphere = new THREE.Mesh(geometry, materials[materialType]);
    sphere.geometry.attributes.uv2 = sphere.geometry.attributes.uv;
    scene.add(sphere);
    sphereRef.current = sphere; // Store the sphere mesh reference

    // Add a ground plane to catch the shadow
    const planeGeometry = new THREE.PlaneGeometry(10, 10);
    const planeMaterial = new THREE.ShadowMaterial({ opacity: 0.5 });
    const plane = new THREE.Mesh(planeGeometry, planeMaterial);
    plane.rotation.x = -Math.PI / 2;
    plane.position.y = -1;
    plane.receiveShadow = true;
    scene.add(plane);

    // Camera position
    camera.position.z = 3;

    // Random floating animation
    const clock = new THREE.Clock();
    const animate = () => {
      requestAnimationFrame(animate);

      const elapsed = clock.getElapsedTime();

      sphere.position.y = Math.sin(elapsed * 0.5) * 0.5 + Math.sin(elapsed * 0.8) * 0.2;
      sphere.position.x = Math.sin(elapsed * 0.3) * 0.2;
      sphere.position.z = Math.sin(elapsed * 0.4) * 0.2;

      renderer.render(scene, camera);
    };

    animate();

    return () => {
      mountRef.current.removeChild(renderer.domElement);
    };
  }, []);

  useEffect(() => {
    if (sphereRef.current) {
      sphereRef.current.material = materials[materialType]; // Update the material
    }
  }, [materialType, color]);

  return (
    <>
      <div ref={mountRef} style={{ height: '100%', minHeight: '250px', backgroundColor: 'transparent' }} />
    </>
  );
};

export default FloatingOrb;
