// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

// import 

// normalize CSS across browsers
import "./src/css/normalize.css"

// custom CSS styles
import "./src/css/global.css"

import React from 'react';
import Layout from './src/components/layout';

export const wrapPageElement = ({ element }) => {
  return <Layout>{element}</Layout>;
};