exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artworks-js": () => import("./../../../src/pages/artworks.js" /* webpackChunkName: "component---src-pages-artworks-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-published-works-js": () => import("./../../../src/pages/published-works.js" /* webpackChunkName: "component---src-pages-published-works-js" */),
  "component---src-pages-sound-js": () => import("./../../../src/pages/sound.js" /* webpackChunkName: "component---src-pages-sound-js" */),
  "component---src-templates-custom-post-js": () => import("./../../../src/templates/customPost.js" /* webpackChunkName: "component---src-templates-custom-post-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

